import React from "react";
import { graphql, Link } from "gatsby";
import Img from "gatsby-image";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const Partner = ({ data }) => {
  const { companyName, offering, memberExclusive, bio, contacts, logo } = data.contentfulPartner;
  const Partners = data.allContentfulPartner.edges;
  return (
    <Layout>
      <SEO title={companyName} />
      <div className="">
        <Img
          className="object-cover w-full h-64"
          fluid={data.file.childImageSharp.fixed}
          alt="Header"
        />
      </div>
      <div className="container px-4 mx-auto mt-12 mb-40">
        <h1 className="title">{companyName}</h1>

        <div className="flex flex-wrap mt-10">
          <div className="sm:w-2/3">

            <div className="">
                {documentToReactComponents(bio.json)}
              
            </div>
            <div className="bg-white px-6 pt-8 pb-32 mt-12">
              <div className="pb-16">
                <h2>Offering</h2>
                <div className="text-base"
                    dangerouslySetInnerHTML={{
                  __html: offering.childMarkdownRemark.html,
                }}
             />
              </div>
              <div className="pb-12">
                <h2>Resident Exclusives</h2>
                {documentToReactComponents(memberExclusive.json)}  
              </div>
  
              <div className="pb-16">
  
  
                <h2>Contact</h2>
                <div className="flex justify-between flex-wrap">
                  {
                    contacts.map((contacts) =>
  
                      <div className="pr-8 mb-8">
                        <div><strong>{contacts.name}</strong></div>
                        <div>{contacts.title}</div>
                        <div><a className="text-purple-800" href={"mailto:" + contacts.email}>{contacts.email}</a></div>
                      </div>
  
  
                    )
                  }
                </div>
            </div>



            </div>

          </div>
          <div className="hidden sm:block sm:w-1/3 px-16">

            <Img
              className="object-cover w-full"
              fluid={logo.fluid}
              alt="Header"
            />


          </div>
        </div>
        <div className="hidden w-full :w-2/3 text-xs mt-12">
          {Partners.map(({ node: post }) => (

            
            <Link className="text-purple-800 mr-2 whitespace-no-wrap" to={`/partners/${post.slug}`}>{post.companyName}</Link>
            
          ))}
        </div>
      </div>
    </Layout>
  );
};
export default Partner;
export const pageQuery = graphql`
  query($slug: String!) {
    contentfulPartner(slug: { eq: $slug }) {
      companyName
      slug
      logo {
        fluid(maxWidth: 2000, resizingBehavior: SCALE) {
          ...GatsbyContentfulFluid_tracedSVG
        }
      }
      bio {
        json
      }
      offering {
        childMarkdownRemark {
          html
        }
      }
      memberExclusive {
        json
      }
      website
      contacts {
        name
        email
        title
      }
    }
    allContentfulPartner(sort: {fields: companyName, order: ASC}) {
      edges {
        node {
          id
          slug
          companyName
        }
      }
    }       
    file(relativePath: { eq: "banner-partners.jpg" }) {
      childImageSharp {
        fixed(width: 1200) {
          ...GatsbyImageSharpFixed
        }
      }
    }    
  }
`;